<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">关于我们</div>
      <div class="con"><span> 于2019年12月成立，致力于开发电子竞技构建数字内容生态，为用户提供更多了安全的娱乐渠道，使用独特的交易模式，促使steam娱乐更快捷安全的提取到用户得库存，被众多玩家所喜爱。</span><br>
        <span>92csgo.com是国内货涵盖全面的CSGO饰品交易品牌。92csgo.com网站上，你可以即时获得更多的csgo的物品，特惠商城有海量的商品可随时取回到库存，其库存量没有任何一家同类型网站可以比拟。<br></span>
        <!-- <span>联系地址：301-309 Nathan Road, Yau Tsim Mong, Hong Kong</span><br> -->
        <span>联系方式：</span><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    // padding-top: 88px;
    // background-color: #2b2c37;
    padding: 32px;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
</style>